<template>
  <div class="settings-page">
    <div class="page-header">
      <h4>Invoice — {{ invoice.number }}</h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item home">
          <router-link to="/">Home</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/invoices">Invoices</router-link>
        </li>
        <li class="breadcrumb-item active">
          Invoice Details
        </li>
      </ol>
    </div>
    <div class="repairer-view-body">

      <tabs ref="invoice-details-tabs" cache-lifetime="0">
        <tab name="Details" id="invoice-view-details" ref="repairerViewDetailsTab" :class-name="'tab-content-table'">
          <invoice-view-details :invoice="invoice"></invoice-view-details>
        </tab>
        <tab name="Notes" id="invoice-view-notes" :class-name="'tab-content-table'">
          <invoice-view-notes ref="notes-view"
                              :notes="notes"
                              @notes-updated="loadInvoice"
          ></invoice-view-notes>
        </tab>
        <tab name="Receipt" id="invoice-view-receipt" :class-name="'tab-content-table'">
          <invoice-view-receipt :invoice="invoice"></invoice-view-receipt>
        </tab>
        <tab name="Transaction History" id="invoice-view-transactions" :class-name="'tab-content-table'">
          <invoice-view-transactions :invoice="invoice" :transactions="transactions"></invoice-view-transactions>
        </tab>

        <template slot="nav-item-right">

          <!-- mobile options -->
          <div class="ml-4">
              <b-dropdown class="dropdown-options dropdown-options--icon-only" right dropleft no-caret>
                  <template v-slot:button-content>
                      <span class="dropdown-arrow">
                          <i class='bx bxs-chevron-down'></i>
                      </span>
                  </template>

                  <b-dropdown-item v-if="!isUserRoleLawyer" @click="addNotePressed">Add Note</b-dropdown-item>
                  <b-dropdown-item v-if="!isUserRoleLawyer" @click="showVoidModal">Mark as Void</b-dropdown-item>
                  <b-dropdown-item v-if="!isUserRoleLawyer" @click="showPaidModal($event)">Mark as Paid</b-dropdown-item>
                  <b-dropdown-item v-if="originalCompanyInfo.activeXeroAuth && !invoice.xeroInvoiceNumber && !isUserRoleLawyer" @click="exportToXero">Send to Xero</b-dropdown-item>
                  <b-dropdown-item v-if="originalCompanyInfo.activeXeroAuth &&  invoice.xeroInvoiceNumber && !isUserRoleLawyer" @click="updateOnXero">Update on Xero</b-dropdown-item>
                  <b-dropdown-item v-if="originalCompanyInfo.activeXeroAuth &&  invoice.xeroInvoiceNumber" @click="viewOnXero">View on Xero</b-dropdown-item>
                  <b-dropdown-item @click="downloadInvoicePdf">Download Invoice</b-dropdown-item>
                  <b-dropdown-item @click="downloadInvoiceReceiptPdf">Download Receipt</b-dropdown-item>
              </b-dropdown>
          </div>
          
          <div class="tabs-nav-right-options ml-4">
            <b-dropdown id="repairer-supplier-view-options" text="Options" class="dropdown-options">
              <template v-slot:button-content>
                Options
                <span class="dropdown-arrow">
                  <i class='bx bxs-chevron-down'></i>
                </span>
              </template>

              <b-dropdown-item v-if="!isUserRoleLawyer" @click="addNotePressed">Add Note</b-dropdown-item>
              <b-dropdown-item v-if="!isUserRoleLawyer" @click="showVoidModal">Mark as Void</b-dropdown-item>
              <b-dropdown-item v-if="!isUserRoleLawyer" @click="showPaidModal($event)">Mark as Paid</b-dropdown-item>
              <b-dropdown-item v-if="originalCompanyInfo.activeXeroAuth && !invoice.xeroInvoiceNumber && !isUserRoleLawyer" @click="exportToXero">Send to Xero</b-dropdown-item>
              <b-dropdown-item v-if="originalCompanyInfo.activeXeroAuth &&  invoice.xeroInvoiceNumber && !isUserRoleLawyer" @click="updateOnXero">Update on Xero</b-dropdown-item>
              <b-dropdown-item v-if="originalCompanyInfo.activeXeroAuth &&  invoice.xeroInvoiceNumber" @click="viewOnXero">View on Xero</b-dropdown-item>
              <b-dropdown-item @click="downloadInvoicePdf">Download Invoice</b-dropdown-item>
              <b-dropdown-item @click="downloadInvoiceReceiptPdf">Download Receipt</b-dropdown-item>

            </b-dropdown>
          </div>
        </template>

      </tabs>

    </div>

    <b-modal ref="newPaidModal" id="new-paid-modal" size="custom" title="Mark As Paid">
      <template v-slot:modal-backdrop class="my-backdrop"></template>
      <template v-slot:modal-header>
        <h5 class="modal-title">Mark As Paid</h5>
      </template>
      <div class="modal-invite-customer form-container">
        <table class="table b-table table-hover">
          <thead class="">
          <tr>
            <th>Invoice</th>
            <th class="">Assessment</th>
            <th class="">Payment Type</th>
            <th class="">Invoice Total</th>
            <th class="">Amount Due</th>
            <th class="">Amount Paid</th>
            <th class="">Date Paid</th>
          </tr>
          </thead>
          <tbody>
          <tr class="clickable">
            <td class="text-center" style="padding-top: 16px;">{{ invoice.number }}</td>
            <td style="padding-top: 16px;">{{ invoice.assessmentNumber }}</td>
            <td style="padding-top: 10px; width:150px">
              <multiselect
                  ref="multipaymenttype"
                  :options="['EFT','Credit Card','Cash','Cheque']"
                  v-model="payment.payment_type"
                  :showLabels="false"
                  :option-height="29"
                  :max-height="203"
                  :close-on-select="true"
              >
              </multiselect>
            </td>
            <td style="padding-top: 16px;">{{ parseFloat(invoice.due) + parseFloat(invoice.paid) | formatMoney }}</td>
            <td style="padding-top: 16px;">{{ parseFloat(invoice.due) | formatMoney }}</td>
            <td>
              <number-formatter ref="paid.amount" v-model="payment.amount" type="text" class="form-control" placeholder="Amount" format="$0,0.00"></number-formatter>
            </td>
            <td class="text-center">
              <b-form-datepicker
                  v-model="payment.date"
                  class="mb-2 form-control"
                  locale="en-GB"
                  :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
              ></b-form-datepicker>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button class="input-btn cancel" @click="$bvModal.hide('new-paid-modal')">Cancel</b-button>
        <button @click="paidInvoice" type="button" class="btn input-btn btn-primary">Paid</button>
      </template>
    </b-modal>

    <b-modal ref="newVoidModal" id="new-void-modal" size="custom" title="Mark As Void">
      <template v-slot:modal-backdrop class="my-backdrop"></template>
      <template v-slot:modal-header>
        <h5 class="modal-title">Mark As Void</h5>
      </template>
      <div class="modal-invite-customer form-container">
        Are you sure you want to void this Invoice #{{invoice.number}}?
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button class="input-btn cancel" @click="$bvModal.hide('new-void-modal')">Cancel</b-button>
        <button @click="voidInvoice" type="button" class="btn input-btn btn-primary">Void</button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import Axios from "axios";
import InvoiceViewDetails from './invoice-view/details';
import InvoiceViewNotes from './invoice-view/notes';
import InvoiceViewReceipt from './invoice-view/receipt';
import InvoiceViewTransactions from './invoice-view/transactions';
import _ from 'lodash';
import {mapGetters} from "vuex";
import moment from "moment";
import Multiselect from "vue-multiselect";
import NumberFormatter from '@/components/number-formatter';

export default {
  name: "invoice-view",
  components: {
    InvoiceViewDetails,
    InvoiceViewNotes,
    InvoiceViewReceipt,
    InvoiceViewTransactions,
    Multiselect,
    NumberFormatter,
  },
  mounted() {
    this.loadInvoice();
    this.debouncedSaveInvoice = _.debounce(this.saveInvoice, 2000);
  },
  data(){
    return {
      contextOpenEvent: null,
      debouncedSaveInvoice: () => {},
      payment: {
        payment_type: 'EFT',
        amount: 0,
      },
      invoice: {
        id: 0,
        number: '',
        claimNumber: '',
        assessmentId: 0,
        assessmentNumber: '',
        vehicleOwner: '',
        createdOn: '',
        dueDate: '',
        dateInvoice: '',
        paid: 0,
        due: 0,
        status: '',
        description: '',
        xeroInvoiceNumber: '',
      },
      transactions: [
        {
          id: 0,
          invoiceId: 0,
          userId: 0,
          paymentType: '',
          paid: 0,
          paidOn: '',
          due: 0,
          createdOn: '',
        }
      ],
      notes: [
        {
          id: 0,
          text: '',
          createdBy: '',
          createdOn: '',
          updatedOn: '',
        }
      ],
      preventUpdate: false,
    };
  },
  methods: {
    loadInvoice(id = null){
      id = id || this.$route.params.invoiceId;
      NProgress.start();
      Axios.get(`/ir/invoice/${id}`)
          .then(response => {
            if(response.data && response.data._status){
              this.preventUpdate = true;
              this.invoice = response.data.invoice;
              this.transactions = response.data.transactions;
              this.notes = response.data.notes;
              setTimeout(() => {
                this.preventUpdate = false;
              }, 100)
            }
          })
          .catch(e => console.error(e))
          .finally(() => {
            NProgress.done();
          });
    },
    addNotePressed(){
      this.$refs["invoice-details-tabs"].selectTab('#invoice-view-notes');
      this.$refs["notes-view"].showAddNoteModal();
    },
    saveInvoice(){
      NProgress.start();
      Axios.post(`/ir/invoice/${this.invoice.id}/update`, this.invoice)
          .then(response => {
            if(response.data && response.data._status){
              this.loadInvoice();
              this.$toast.success('Invoice updated successfully!');
            }
          })
          .catch(e => console.error(e))
          .finally(() => {
            NProgress.done();
          });
    },
    exportToXero(){
      Axios.post(`/ir/invoice/${this.invoice.id}/send-to-xero`)
          .then(response => {
            if(response.data && response.data._status){
              this.$toast.success('Sent to Xero! Invoice number is ' + response.data.invoiceNumber);
              this.loadInvoices();
            } else if(response.data._error){
              this.$toast.error('Error: ' + response.data._error);
            } else {
              this.$toast.error('Something went wrong');
            }
          })
          .catch(e => console.log(e));
    },
    updateOnXero(){
      Axios.post(`/ir/invoice/${this.invoice.id}/send-to-xero`)
          .then(response => {
            if(response.data && response.data._status){
              this.$toast.success('Invoice ' + response.data.invoiceNumber + ' has been updated on Xero');
              this.loadInvoices();
            } else if(response.data._error){
              this.$toast.error('Error: ' + response.data._error);
            } else {
              this.$toast.error('Something went wrong');
            }
          })
          .catch(e => console.log(e));
    },
    viewOnXero(){
      Axios.get(`/ir/invoice/${this.invoice.id}/get-xero-url`)
          .then(response => {
            if(response.data && response.data._status){
              window.open(response.data.url, '__blank');
            } else if(response.data._error){
              this.$toast.error('Error: ' + response.data._error);
            } else {
              this.$toast.error('Something went wrong');
            }
          })
          .catch(e => console.log(e));
    },
    showVoidModal() {
      this.contextClose();
      this.$refs.newVoidModal.show();
    },
    contextClose() {
      let event = this.contextOpenEvent;
      if (event) {
        let row = event.target.parentElement;
        let rows = row.parentElement.rows;
        if(rows) for (let i = 0; i < rows.length; i++) {
          let elem = rows[i];
          elem.style.backgroundColor = "";
        }
      }
    },
    showPaidModal($event){
      this.contextOpenEvent = $event
      this.payment.amount = this.invoice.due;
      this.payment.date = moment().format('YYYY-MM-DD');
      this.contextClose();
      this.$refs.newPaidModal.show();
    },
    paidInvoice() {
      this.makePayment();
      this.$refs.newPaidModal.hide();
    },
    makePayment(){
      Axios.post('/ir/invoices/paid', {
        invoiceId: this.invoice.id,
        paymentType: this.payment.payment_type,
        paid: this.payment.amount,
        paidOn: this.payment.date,
      })
          .then(response => {
            if(response && response.data && response.data._status){
              this.$toast.success('Payment Completed');
              this.loadInvoice();
            } else {
              this.$toast.error('Cannot add payment');
            }
          })
          .catch(e => console.error(e))
          .finally(() => {
            this.loadInvoices();
          });
    },
    voidInvoice(){
      this.$refs.newVoidModal.hide();
      Axios.post(`/ir/invoice/${this.invoice.id}/void`)
          .then(response => {
            if(response && response.data && response.data._status){
              this.$toast.success('Invoice Voided');
            } else {
              this.$toast.error('Cannot void invoice');
            }
          })
          .catch(e => console.error(e))
          .finally(() => {
            this.loadInvoices();
          });
    },
    downloadInvoicePdf () {
      let token =  localStorage.getItem('token');
      window.open(appConfig.baseAPIURL+`/ir/invoice/${this.invoice.id}/invoice-pdf&at=${token}`);
    },
    downloadInvoiceReceiptPdf(){
      let token =  localStorage.getItem('token');
      window.open(appConfig.baseAPIURL+`/ir/invoice/${this.invoice.id}/receipt-pdf&at=${token}`);
    },
  },
  computed: {
    ...mapGetters({
      originalCompanyInfo: "currentCompany/getDetails",
      getterCurrentCompanyId: "currentCompany/getCompanyId",
      isUserRoleLawyer: 'currentUser/isRoleLawyer',
    }),
  },
  watch:{
    invoice: {
      deep: true,
      handler(){
        if(this.preventUpdate) return;
        this.debouncedSaveInvoice();
      }
    }
  },
}
</script>


<style>
#new-paid-modal___BV_modal_outer_,
#new-void-modal___BV_modal_outer_ {
  z-index: 1000040!important;
}
.modal-dialog.modal-custom {
  top: 16%;
}
.modal-dialog.modal-custom {
  max-width: 623px;
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-weight: bold;
}

#new-paid-modal .modal-dialog.modal-custom{
  min-width:850px;
}

#new-void-modal .modal-body {
  min-height: 50px;
}

.modal-dialog.modal-custom .modal-content {
  border-radius: 5px;
  border: 0;
}

.modal-dialog.modal-custom .modal-body {
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
}

.modal-dialog.modal-custom .modal-content header {
  border-radius: 3px 3px 0 0;
  background-color: rgba(27, 30, 56, 0.07);
  border: 0;
  margin: 10px;
}

.modal-dialog.modal-custom .modal-content header .modal-title {
  font-weight: bold;
}

.modal-dialog .modal-footer {
  border-top: none;
  padding-left: 20px;
  padding-right: 20px;
}

#custom-modal___BV_modal_backdrop_ {
  background-color: rgba(27, 30, 56, 0.25);
}
</style>

<style scoped>
.modal-dialog.modal-custom {
  max-width: 623px;
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-weight: bold;
  top: 16%;
}

.modal-dialog.modal-custom .modal-content {
  border-radius: 5px;
  border: 0;
}

.modal-dialog.modal-custom .modal-body {
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
}

.modal-dialog.modal-custom .modal-content header {
  border-radius: 3px 3px 0 0;
  background-color: rgba(27, 30, 56, 0.07);
  border: 0;
  margin: 10px;
}

.modal-dialog.modal-custom .modal-content header .modal-title {
  font-weight: bold;
}

.modal-dialog .modal-footer {
  border-top: none;
  padding-left: 20px;
  padding-right: 20px;
}

#custom-modal___BV_modal_backdrop_ {
  background-color: rgba(27, 30, 56, 0.25);
}

.modal-invite-customer {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.modal-invite-customer .form-control.text-message {
  width: 100%;
  height: 313px;
  border-radius: 3px;
}

.modal-invite-customer .input-row label {
  width: 240px;
  font-size: 12px;
  font-weight: bold;
}

.modal-invite-customer .email-list {
  color: #29BBC1;
}

.modal-invite-customer .form-control {
  height: 31px;
  border-width: 1.5px;
  border-radius: 3px;
}

.modal-invite-customer input[readonly] {
  opacity: 1;
  color: #000000;
}

.modal-invite-customer input[readonly]:focus {
  box-shadow: none;
  border: 1.5px solid rgba(28, 31, 57, 0.1);
}

.input-btn {
  width: auto;
  font-size: 13px;
  width: 83px;
  height: 38px;
}

.modal-backdrop {
  background: red;
}
</style>


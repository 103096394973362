<template>
    <div class="claimlinq-page page-invoices">
        <div class="page-header">
            <h4>Invoices</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item">
                    <router-link :to="'/dashboard'">Home</router-link>
                </li>
                <li class="breadcrumb-item active">View Invoices</li>
            </ol>
            <div class="page-header-nav-btn">
            </div>
        </div>
        <div class="claimlinq-body">
            <tabs ref="claimlinq-table" cache-lifetime="0" :options="{useUrlFragment:true}">
                <tab name="All" id="invoices-all" :class-name="'tab-content-table'">
                    <div class="use-pagination-block-bottom">
                        <b-table
                                responsive
                                hover
                                :items="invoices"
                                :fields="allFields"
                                :filter="filter"
                                :filter-function="filterForTable"
                                @filtered="onAllInvoicesFiltered"
                                :sort-desc="computedSortDescForInvoicesAll"
                                :current-page="invoicesAllCurrentPage"
                                :sort-by="computedSortByForInvoicesAll"
                                @sort-changed="setSortByAndSortDescForInvoicesAll"
                                :per-page="invoicesPerPage"
                                @row-contextmenu="handleRightClick"
                                @row-clicked="openInvoice"
                        >
                            <template v-slot:head()="data">
                                <div v-if="!data.field.sortable">{{data.label}}</div>
                                <div v-if="data.field.sortable" class="table-sorting">{{data.label}}<i></i></div>
                            </template>
                            <template v-slot:cell(paid)="data">
                                {{ data.item.paid | formatMoney }}
                            </template>
                            <template v-slot:cell(due)="data">
                                {{ data.item.due | formatMoney }}
                            </template>
                            <template v-slot:cell(active)="data">
                                {{ data.item.active ? 'Yes' : 'No' }}
                            </template>
                            <template v-slot:cell(createdOn)="data">
                                {{ data.item.createdOn | formatDatetime }}
                            </template>
                            <template v-slot:cell(actions)="data">
                                <div class="action-btn">
                                    <button
                                            class="clickable btn_pdf"
                                            v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Download Invoice PDF', placement:'leftbottom'}"
                                            @click="downloadInvoicePdf(data.item.id)"
                                    ></button>
                                    <button
                                            class="clickable btn_receipt"
                                            v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Download Receipt', placement:'leftbottom'}"
                                            @click="downloadInvoiceReceiptPdf(data.item.id)"
                                    ></button>
                                    <button
                                            class="clickable btn_email"
                                            v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Send Invoice Out', placement:'leftbottom'}"
                                    ></button>
                                    <!--                  <button-->
                                    <!--                      class="clickable btn_edit"-->
                                    <!--                      v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Mark As Paid', placement:'leftbottom'}"-->
                                    <!--                      @click="showPaidModal(data.item)"></button>-->
                                    <!--                  <button-->
                                    <!--                      class="clickable btn_trush"-->
                                    <!--                      v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Mark As Void', placement:'leftbottom'}"-->
                                    <!--                      @click="showVoidModal(data.item)"-->
                                    <!--                  ></button>-->
                                </div>
                            </template>
                            <template v-slot:cell(status)="data">
                                <span v-if="data.item.status == 'awaiting' && data.item.paid > 0">Partial Payment</span>
                                <span v-else-if="data.item.status == 'awaiting'">Awaiting Payment</span>
                                <span v-else-if="data.item.status == 'paid'">Paid</span>
                                <span v-else-if="data.item.status == 'void'">Void</span>
                            </template>
                        </b-table>
                    </div>

                    <block-pagination
                        :role="'invoices'"
                        :arrayOfSomeone="invoices"
                        :arrayOfSomeoneFiltered="computedAllInvoicesFiltered"
                        :currentPage="invoicesAllCurrentPage"
                        @changedCurrentPage="value => $store.commit('invoice/setInvoicesAllCurrentPage', value)"
                        :perPage="invoicesPerPage"
                        :perPageForMultipleTables="computedPerPage"
                        @changedPerPage="value => $store.commit('invoice/setInvoicesPerPage', value)"
                    >
                    </block-pagination>
                </tab>
                <tab name="Awaiting Payment" id="invoices-awaiting-payment" :class-name="'tab-content-table'">
                    <div class="use-pagination-block-bottom">
                        <b-table
                                responsive
                                hover
                                :items="awaitingPaymentInvoices"
                                :fields="allFields"
                                :filter-function="filterForTable"
                                :filter="filter"
                                @filtered="onAwaitingInvoicesFiltered"
                                @row-contextmenu="handleRightClick"
                                :sort-desc="computedSortDescForInvoicesAwaitingPayment"
                                :sort-by="computedSortByForInvoicesAwaitingPayment"
                                @sort-changed="setSortByAndSortDescForInvoicesAwaitingPayment"
                                :current-page="invoicesAwaitingPaymentCurrentPage"
                                :per-page="invoicesPerPage"
                                @row-clicked="openInvoice"
                        >
                            <template v-slot:head()="data">
                                <div v-if="!data.field.sortable">{{data.label}}</div>
                                <div v-if="data.field.sortable" class="table-sorting">{{data.label}}<i></i></div>
                            </template>
                            <template v-slot:cell(paid)="data">
                                {{ data.item.paid | formatMoney }}
                            </template>
                            <template v-slot:cell(due)="data">
                                {{ data.item.due | formatMoney }}
                            </template>
                            <template v-slot:cell(active)="data">
                                {{ data.item.active ? 'Yes' : 'No' }}
                            </template>
                            <template v-slot:cell(createdOn)="data">
                                {{ data.item.createdOn | formatDatetime }}
                            </template>
                            <template v-slot:cell(actions)="data">
                                <div class="action-btn">
                                    <button
                                            class="clickable btn_pdf"
                                            v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Download Invoice PDF', placement:'leftbottom'}"
                                            @click="downloadInvoicePdf(data.item.id)"
                                    ></button>
                                    <button
                                            class="clickable btn_receipt"
                                            v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Download Receipt', placement:'leftbottom'}"
                                            @click="downloadInvoiceReceiptPdf(data.item.id)"
                                    ></button>
                                    <button
                                            class="clickable btn_email"
                                            v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Send Invoice Out', placement:'leftbottom'}"
                                    ></button>
                                    <!--                  <button-->
                                    <!--                      class="clickable btn_edit"-->
                                    <!--                      v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Mark As Paid', placement:'leftbottom'}"-->
                                    <!--                      @click="showPaidModal(data.item)"></button>-->
                                    <!--                  <button-->
                                    <!--                      class="clickable btn_trush"-->
                                    <!--                      v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Mark As Void', placement:'leftbottom'}"-->
                                    <!--                      @click="showVoidModal(data.item)"-->
                                    <!--                  ></button>-->
                                </div>
                            </template>
                            <template v-slot:cell(status)="data">
                                <span v-if="data.item.status == 'awaiting' && data.item.paid > 0">Partial Payment</span>
                                <span v-else-if="data.item.status == 'awaiting'">Awaiting Payment</span>
                                <span v-else-if="data.item.status == 'paid'">Paid</span>
                                <span v-else-if="data.item.status == 'void'">Void</span>
                            </template>
                        </b-table>
                    </div>

                    <block-pagination
                        :role="'invoices'"
                        :arrayOfSomeone="awaitingPaymentInvoices"
                        :arrayOfSomeoneFiltered="computedAwaitingPaymentInvoicesFiltered"
                        :currentPage="invoicesAwaitingPaymentCurrentPage"
                        @changedCurrentPage="value => $store.commit('invoice/setInvoicesAwaitingPaymentCurrentPage', value)"
                        :perPage="invoicesPerPage"
                        :perPageForMultipleTables="computedPerPage"
                        @changedPerPage="value => $store.commit('invoice/setInvoicesPerPage', value)"
                    >
                    </block-pagination>
                </tab>
                <tab name="Paid" id="invoices-paid" :class-name="'tab-content-table'">
                    <div class="use-pagination-block-bottom">
                        <b-table
                                responsive
                                hover
                                :items="paidInvoices"
                                :fields="allFields"
                                :filter="filter"
                                :filter-function="filterForTable"
                                @filtered="onPaidInvoicesFiltered"
                                @row-contextmenu="handleRightClick"
                                :sort-desc="computedSortDescForInvoicesPaid"
                                :sort-by="computedSortByForInvoicesPaid"
                                @sort-changed="setSortByAndSortDescForInvoicesPaid"
                                :current-page="invoicesPaidCurrentPage"
                                :per-page="invoicesPerPage"
                                @row-clicked="openInvoice"
                        >
                            <template v-slot:head()="data">
                                <div v-if="!data.field.sortable">{{data.label}}</div>
                                <div v-if="data.field.sortable" class="table-sorting">{{data.label}}<i></i></div>
                            </template>
                            <template v-slot:cell(paid)="data">
                                {{ data.item.paid | formatMoney }}
                            </template>
                            <template v-slot:cell(due)="data">
                                {{ data.item.due | formatMoney }}
                            </template>
                            <template v-slot:cell(active)="data">
                                {{ data.item.active ? 'Yes' : 'No' }}
                            </template>
                            <template v-slot:cell(createdOn)="data">
                                {{ data.item.createdOn | formatDatetime }}
                            </template>
                            <template v-slot:cell(actions)="data">
                                <div class="action-btn">
                                    <button
                                            class="clickable btn_pdf"
                                            v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Download Invoice PDF', placement:'leftbottom'}"
                                            @click="downloadInvoicePdf(data.item.id)"
                                    ></button>
                                    <button
                                            class="clickable btn_receipt"
                                            v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Download Receipt', placement:'leftbottom'}"
                                            @click="downloadInvoiceReceiptPdf(data.item.id)"
                                    ></button>
                                    <button
                                            class="clickable btn_email"
                                            v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Send Invoice Out', placement:'leftbottom'}"
                                    ></button>
                                </div>
                            </template>
                            <template v-slot:cell(status)="data">
                                <span v-if="data.item.status == 'awaiting' && data.item.paid > 0">Partial Payment</span>
                                <span v-else-if="data.item.status == 'awaiting'">Awaiting Payment</span>
                                <span v-else-if="data.item.status == 'paid'">Paid</span>
                                <span v-else-if="data.item.status == 'void'">Void</span>
                            </template>
                        </b-table>
                    </div>

                    <block-pagination
                        :role="'invoices'"
                        :arrayOfSomeone="paidInvoices"
                        :arrayOfSomeoneFiltered="computedPaidInvoicesFiltered"
                        :currentPage="invoicesPaidCurrentPage"
                        @changedCurrentPage="value => $store.commit('invoice/setInvoicesPaidCurrentPage', value)"
                        :perPage="invoicesPerPage"
                        :perPageForMultipleTables="computedPerPage"
                        @changedPerPage="value => $store.commit('invoice/setInvoicesPerPage', value)"
                    >
                    </block-pagination>
                </tab>

                <template slot="nav-item-right">
                    <div class="tabs-nav-right-search">
                        <b-form-input v-model="filter" type="text" class="search-input" placeholder="Search or Filter results"
                                      debounce="100"></b-form-input>
                    </div>
                </template>
            </tabs>
            <vue-context
                    ref="invoices-context-menu"
                    class="context-menu-right-click list-group invoices-context-menu"
                    :closeOnClick="true"
                    :closeOnScroll="true"
                    :subMenuOffset="100"
                    @open="contextOpen"
                    @close="contextClose"
            >
                <template slot-scope="item">
                    <b-button-group ref="ctx-button-group" vertical class="d-flex" v-if="item && item.data">
                        <b-button v-if="!isUserRoleLawyer" ref="ctx-button-1" class="text-left font-12 font-nunito--semibold" variant="light" block @click="showVoidModal($event, item.data)">Mark as Void</b-button>
                        <b-button v-if="!isUserRoleLawyer" ref="ctx-button-1" class="text-left font-12 font-nunito--semibold" variant="light" block @click="showPaidModal($event, item.data)">Mark as Paid</b-button>
                        <b-button v-if="!isUserRoleLawyer && originalCompanyInfo.activeXeroAuth && !item.data.xeroInvoiceNumber" ref="ctx-button-1" class="text-left font-12 font-nunito--semibold" variant="light" block
                                  @click="exportToXero(item.data.id)">Send to Xero
                        </b-button>
                        <b-button v-if="!isUserRoleLawyer && originalCompanyInfo.activeXeroAuth && item.data.xeroInvoiceNumber" ref="ctx-button-1" class="text-left font-12 font-nunito--semibold" variant="light" block
                                  @click="updateOnXero(item.data.id)">Update on Xero
                        </b-button>
                        <b-button v-if="originalCompanyInfo.activeXeroAuth && item.data.xeroInvoiceNumber" ref="ctx-button-1" class="text-left font-12 font-nunito--semibold" variant="light" block
                                  @click="viewOnXero(item.data.id)">View on Xero
                        </b-button>
                    </b-button-group>
                </template>
            </vue-context>

        </div>
        <b-modal ref="newPaidModal" id="new-paid-modal" size="custom" title="Mark As Paid">
            <template v-slot:modal-backdrop class="my-backdrop"></template>
            <template v-slot:modal-header>
                <h5 class="modal-title">Mark As Paid</h5>
            </template>
            <div class="modal-invite-customer form-container">
                <table class="table b-table table-hover">
                    <thead class="">
                    <tr>
                        <th>Invoice</th>
                        <th class="">Assessment</th>
                        <th class="">Payment Type</th>
                        <th class="">Invoice Total</th>
                        <th class="">Amount Due</th>
                        <th class="">Amount Paid</th>
                        <th class="">Date Paid</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="clickable">
                        <td class="text-center" style="padding-top: 16px;">{{ invoice.number }}</td>
                        <td style="padding-top: 16px;">{{ invoice.assessmentNumber }}</td>
                        <td style="padding-top: 10px; width:150px">
                            <multiselect
                                    ref="multipaymenttype"
                                    :options="['EFT','Credit Card','Cash','Cheque']"
                                    v-model="payment.payment_type"
                                    :showLabels="false"
                                    :option-height="29"
                                    :max-height="203"
                                    :close-on-select="true"
                            >
                            </multiselect>
                        </td>
                        <td style="padding-top: 16px;">{{ parseFloat(invoice.due) + parseFloat(invoice.paid) | formatMoney }}</td>
                        <td style="padding-top: 16px;">{{ parseFloat(invoice.due) | formatMoney }}</td>
                        <td>
                            <number-formatter ref="paid.amount" v-model="payment.amount" type="text" class="form-control" placeholder="Amount" format="$0,0.00"></number-formatter>
                        </td>
                        <td class="text-center">
                            <b-form-datepicker
                                    v-model="payment.date"
                                    class="mb-2 form-control"
                                    locale="en-GB"
                                    :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
                            ></b-form-datepicker>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button class="input-btn cancel" @click="$bvModal.hide('new-paid-modal')">Cancel</b-button>
                <button @click="paidInvoice" type="button" class="btn input-btn btn-primary">Paid</button>
            </template>
        </b-modal>


        <b-modal ref="newVoidModal" id="new-void-modal" size="custom" title="Mark As Void">
            <template v-slot:modal-backdrop class="my-backdrop"></template>
            <template v-slot:modal-header>
                <h5 class="modal-title">Mark As Void</h5>
            </template>
            <div class="modal-invite-customer form-container">
                Are you sure you want to void this Invoice #{{invoice.number}}?
            </div>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button class="input-btn cancel" @click="$bvModal.hide('new-void-modal')">Cancel</b-button>
                <button @click="voidInvoice" type="button" class="btn input-btn btn-primary">Void</button>
            </template>
        </b-modal>


    </div>
</template>

<script>
    /*eslint-disable */
    import Axios from "axios";
    import {VueContext} from 'vue-context';
    import NumberFormatter from '@/components/number-formatter';
    import BlockPagination from '../../components/utility/block-pagination';
    // import DatePicker from '@/views/utility/date-picker';
    import Multiselect from "vue-multiselect";
    import moment from "moment";
    import {mapActions, mapGetters} from 'vuex';
    import _ from "lodash";

    export default {
        name: 'Invoices',
        data() {
            return {
                contextOpenEvent: null,
                invoices: [],
                awaitingPaymentInvoices: [],
                paidInvoices: [],
                allFields: [
                    {label: "Invoice Nbr", key: "number", sortable: true, tdClass: 'clickable number', thClass: ''},
                    {label: "Ref Nbr", key: "claimNumber", sortable: true, tdClass: 'clickable ', thClass: ''},
                    {label: "Vehicle Ownr", key: "vehicleOwner", sortable: true, tdClass: 'clickable number', thClass: ''},
                    {label: "Rego", key: "rego", sortable: true, tdClass: 'clickable number', thClass: ''},
                    {label: "Legal Ref", key: "legalRef", sortable: true, tdClass: 'clickable number', thClass: ''},
                    {label: "Legal Firm", key: "legalFirmName", sortable: true, tdClass: 'clickable number', thClass: ''},
                    {label: "Date Created", key: "createdOn", sortable: true, tdClass: 'clickable ', thClass: ''},
                    {label: "Due Date", key: "dueDate", sortable: true, tdClass: 'clickable ', thClass: ''},
                    {label: "Paid", key: "paid", sortable: true, tdClass: 'clickable ', thClass: ''},
                    {label: "Due", key: "due", sortable: true, tdClass: 'clickable ', thClass: ''},
                    {label: "Actions", key: "actions", sortable: true, tdClass: 'clickable', thClass: ''},
                    {label: "Invoice Status", key: "status", sortable: true, tdClass: 'clickable', thClass: ''},
                ],
                // invoices: [],
                invoice: {
                    number: 0,
                    id: 0,
                },
                payment: {
                    payment_type: 'EFT',
                    amount: 0,
                },
                dpOptions: {},
                invoicesAllFiltered: [],
                invoicesAwaitingPaymentFiltered: [],
                invoicesPaidFiltered: [],
            };
        },
        computed: {
            ...mapGetters({
                originalCompanyInfo: "currentCompany/getDetails",
                getterCurrentCompanyId: "currentCompany/getCompanyId",
                getterInvoices: "invoice/getAllInvoices",
                gettersFilter: 'invoice/getFilter',
                getterSortByForInvoicesAll: 'invoice/getSortByForInvoicesAll',
                getterSortDescForInvoicesAll: 'invoice/getSortDescForInvoicesAll',
                getterSortByForInvoicesAwaitingPayment: 'invoice/getSortByForInvoicesAwaitingPayment',
                getterSortDescForInvoicesAwaitingPayment: 'invoice/getSortDescForInvoicesAwaitingPayment',
                getterSortByForInvoicesPaid: 'invoice/getSortByForInvoicesPaid',
                getterSortDescForInvoicesPaid: 'invoice/getSortDescForInvoicesPaid',
                getterPerPage: 'invoice/getInvoicesPerPage',
                getterInvoicesAllCurrentPage: 'invoice/getInvoicesAllCurrentPage',
                getterInvoicesAwaitingPaymentCurrentPage: 'invoice/getInvoicesAwaitingPaymentCurrentPage',
                getterInvoicesPaidCurrentPage: 'invoice/getInvoicesPaidCurrentPage',
                isUserRoleLawyer: 'currentUser/isRoleLawyer',
            }),
            filter: {
              get() {
                return this.gettersFilter
              },
              set(value) {
                this.setFilter(value);
              },
            },
            invoicesPerPage() {
              return this.getterPerPage;
            },
            invoicesAllCurrentPage() {
              return this.getterInvoicesAllCurrentPage;
            },
            invoicesAwaitingPaymentCurrentPage() {
              return this.getterInvoicesAwaitingPaymentCurrentPage
            },
            invoicesPaidCurrentPage() {
              return this.getterInvoicesPaidCurrentPage;
            },
            computedSortByForInvoicesAll() {
              return this.getterSortByForInvoicesAll;
            },
            computedSortDescForInvoicesAll() {
              return this.getterSortDescForInvoicesAll;
            },
            computedSortByForInvoicesAwaitingPayment() {
              return this.getterSortByForInvoicesAwaitingPayment;
            },
            computedSortDescForInvoicesAwaitingPayment() {
              return this.getterSortDescForInvoicesAwaitingPayment;
            },
            computedSortByForInvoicesPaid() {
              return this.getterSortByForInvoicesPaid;
            },
            computedSortDescForInvoicesPaid() {
              return this.getterSortDescForInvoicesPaid;
            },
            computedPerPage() {
              return this.invoicesPerPage;
            },
            computedAllInvoicesFiltered() {
              return this.invoicesAllFiltered;
            },
            computedAwaitingPaymentInvoicesFiltered() {
              return this.invoicesAwaitingPaymentFiltered;
            },
            computedPaidInvoicesFiltered() {
              return this.invoicesPaidFiltered;
            },
        },
        mounted() {
          this.setData();
          this.actionLoadInvoices()
          .then((r)=>{
            this.setData();
          })
            // this.loadInvoices();
        },
        methods: {
            ...mapActions({
              setFilter: 'invoice/setFilter',
              actionLoadInvoices: 'invoice/getInvoicesForTableView',
            }),
            setData() {
              this.invoices = this.getterInvoices;
              this.awaitingPaymentInvoices = _.filter(this.invoices, (i) => {
                return i.status === 'awaiting';
              });
              this.paidInvoices = _.filter(this.invoices, (i) => {
                return i.status === 'paid';
              });
              this.invoicesAllFiltered = this.invoices.slice();
              this.invoicesPaidFiltered = this.paidInvoices.slice();
              this.invoicesAwaitingPaymentFiltered = this.awaitingPaymentInvoices.slice();
            },
            setSortByAndSortDescForInvoicesAll(e) {
              this.$store.commit('invoice/setSortByForInvoicesAll', e.sortBy);
              this.$store.commit('invoice/setSortDescForInvoicesAll', e.sortDesc);
            },
            setSortByAndSortDescForInvoicesAwaitingPayment(e) {
              this.$store.commit('invoice/setSortByForInvoicesAwaitingPayment', e.sortBy);
              this.$store.commit('invoice/setSortDescForInvoicesAwaitingPayment', e.sortDesc);
            },
            setSortByAndSortDescForInvoicesPaid(e) {
              this.$store.commit('invoice/setSortByForInvoicesPaid', e.sortBy);
              this.$store.commit('invoice/setSortDescForInvoicesPaid', e.sortDesc);
            },
            filterForTable(row, filter) {
              if (row.assessmentNumber && row.assessmentNumber.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.claimNumber && row.claimNumber.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.rego && row.rego.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.legalRef && row.legalRef.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.legalFirmName && row.legalFirmName.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.createdOn && row.createdOn.includes(filter.toLowerCase())) {
                return true;
              } else if (row.dateInvoice && row.dateInvoice.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.description && row.description.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.due && row.due == Number(filter)) {
                return true;
              } else if (row.dueDate && row.dueDate.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.gst && row.gst.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.number && row.number.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.paid && row.paid.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.receiptNumber && row.receiptNumber.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.status && row.status.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.subtotal && row.subtotal.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.total && row.total.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.vehicleOwner && row.vehicleOwner.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.xeroInvoiceNumber && row.xeroInvoiceNumber.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              }
              return false;
            },
            onAllInvoicesFiltered(fi) {
              this.invoicesAllFiltered = fi;
            },
            onAwaitingInvoicesFiltered(fi) {
              this.invoicesAwaitingPaymentFiltered = fi;
            },
            onPaidInvoicesFiltered(fi) {
              this.invoicesPaidFiltered= fi;
            },
            exportToXero(id) {
                Axios.post(`/ir/invoice/${id}/send-to-xero`)
                    .then(response => {
                        if (response.data && response.data._status) {
                            this.$toast.success('Sent to Xero! Invoice number is ' + response.data.invoiceNumber);
                            this.loadInvoices();
                        } else if (response.data._error) {
                            this.$toast.error('Error: ' + response.data._error);
                        } else {
                            this.$toast.error('Something went wrong');
                        }
                    })
                    .catch(e => console.log(e));
            },
            updateOnXero(id) {
                Axios.post(`/ir/invoice/${id}/send-to-xero`)
                    .then(response => {
                        if (response.data && response.data._status) {
                            this.$toast.success('Invoice ' + response.data.invoiceNumber + ' has been updated on Xero');
                            this.loadInvoices();
                        } else if (response.data._error) {
                            this.$toast.error('Error: ' + response.data._error);
                        } else {
                            this.$toast.error('Something went wrong');
                        }
                    })
                    .catch(e => console.log(e));
            },
            viewOnXero(id) {
                Axios.get(`/ir/invoice/${id}/get-xero-url`)
                    .then(response => {
                        if (response.data && response.data._status) {
                            window.open(response.data.url, '__blank');
                        } else if (response.data._error) {
                            this.$toast.error('Error: ' + response.data._error);
                        } else {
                            this.$toast.error('Something went wrong');
                        }
                    })
                    .catch(e => console.log(e));
            },
            markAsVoidInvoice(e, data) {
                this.$refs.voidModal.show();
                console.log('void', data);
            },
            openInvoice(item) {
                this.$router.push(`/invoice/${item.id}`);
            },
            handleRightClick(item, index, e) {
                if (e) {
                    e.preventDefault();
                }
                if (this.isUserRoleLawyer && (!this.originalCompanyInfo.activeXeroAuth || !item.xeroInvoiceNumber)) {
                  if (this.$refs['invoices-context-menu']) {
                    this.$refs['invoices-context-menu'].close()
                  }
                  return;
                }

                let row = e.target.parentElement;
                let rows = row.parentElement.rows;
                for (let i = 0; i < rows.length; i++) {
                    let elem = rows[i];
                    elem.style.backgroundColor = "";
                }
                row.style.backgroundColor = "#F3F3F3";
                this.$refs['invoices-context-menu'].open(e, item, index);
            },
            downloadInvoicePdf(invoice_id) {
                let token = localStorage.getItem('token');
                window.open(appConfig.baseAPIURL + `/ir/invoice/${invoice_id}/invoice-pdf&at=${token}`);
            },
            downloadInvoiceReceiptPdf(invoice_id) {
                let token = localStorage.getItem('token');
                window.open(appConfig.baseAPIURL + `/ir/invoice/${invoice_id}/receipt-pdf&at=${token}`);
            },
            loadInvoices() {
                return;
                NProgress.start();
                Axios.get('/ir/invoices')
                    .then(response => {
                        if (response.data && response.data._status) {
                            this.invoices = response.data.invoices;
                        }
                    })
                    .catch(e => console.error(e))
                    .finally(() => {
                        NProgress.done();
                    });
            },
            showPaidModal($event, item) {
                this.contextOpenEvent = $event;
                this.invoice = item;
                this.payment.amount = this.invoice.due;
                this.payment.date = moment().format('YYYY-MM-DD');
                this.contextClose();
                this.$refs.newPaidModal.show();
            },
            showVoidModal($event, item) {
                //  console.log('EVENT', $event)
                this.invoice = item;
                this.contextClose();
                this.$refs.newVoidModal.show();
            },
            paidInvoice() {
                this.makePayment();
                this.$refs.newPaidModal.hide();
            },
            contextOpen(event) {
                this.contextOpenEvent = event;
            },
            contextClose() {
                let event = this.contextOpenEvent;
                if (event) {
                    let row = event.target.parentElement;
                    let rows = row.parentElement.rows;
                    if (rows) for (let i = 0; i < rows.length; i++) {
                        let elem = rows[i];
                        elem.style.backgroundColor = "";
                    }
                }
            },
            makePayment() {
                Axios.post('/ir/invoices/paid', {
                    invoiceId: this.invoice.id,
                    paymentType: this.payment.payment_type,
                    paid: this.payment.amount,
                    paidOn: this.payment.date,
                })
                    .then(response => {
                        if (response && response.data && response.data._status) {
                            this.$toast.success('Payment Completed');
                        } else {
                            this.$toast.error('Cannot add payment');
                        }
                    })
                    .catch(e => console.error(e))
                    .finally(() => {
                        this.loadInvoices();
                    });
            },
            voidInvoice() {
                this.$refs.newVoidModal.hide();
                Axios.post(`/ir/invoice/${this.invoice.id}/void`)
                    .then(response => {
                        if (response && response.data && response.data._status) {
                            this.$toast.success('Invoice Voided');
                        } else {
                            this.$toast.error('Cannot void invoice');
                        }
                    })
                    .catch(e => console.error(e))
                    .finally(() => {
                        this.loadInvoices();
                    });
            }
        },
        components: {
            NumberFormatter,
            Multiselect,
            VueContext,
            BlockPagination,
        }
    };
</script>

<style>
    #new-paid-modal___BV_modal_outer_,
    #new-void-modal___BV_modal_outer_ {
        z-index: 1000040 !important;
    }

    .modal-dialog.modal-custom {
        top: 16%;
    }

    .modal-dialog.modal-custom {
        max-width: 623px;
        font-weight: bold;
    }

    #new-paid-modal .modal-dialog.modal-custom {
        min-width: 850px;
    }

    #new-void-modal .modal-body {
        min-height: 50px;
    }

    .modal-dialog.modal-custom .modal-content {
        border-radius: 5px;
        border: 0;
    }

    .modal-dialog.modal-custom .modal-body {
        padding: 0px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .modal-dialog.modal-custom .modal-content header {
        border-radius: 3px 3px 0 0;
        background-color: rgba(27, 30, 56, 0.07);
        border: 0;
        margin: 10px;
    }

    .modal-dialog.modal-custom .modal-content header .modal-title {
        font-weight: bold;
    }

    .modal-dialog .modal-footer {
        border-top: none;
        padding-left: 20px;
        padding-right: 20px;
    }

    #custom-modal___BV_modal_backdrop_ {
        background-color: rgba(27, 30, 56, 0.25);
    }
</style>

<style scoped>
    .v-context.context-menu-right-click.list-group.invoices-context-menu {
        width: 165px;
    }

    .btn_pdf {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAA70lEQVRIS+2WYQ0CMQxG3ylAAjgAFIADkAAWMHCgBCSAAyzgABygAMiXbAks47rtuMAPliy55LrvtevaraLjUXWsTwwwBbbAwIDvgKXlYAxwBvrWQvffhMQA9wRxea4oNRohpQCtW6RA2gDkvQlpCzAhnwCEkBfNUkDTOfgDzCr5rS2SNyNg7vzeAzdgBailqIrVVo5PcWVFIGMtXgNqgppDYOyKTBAV26QNQOIz4OAAikhTwoJLXDZ+ZEfgm98G8Fuk75NTrIO0ZwHMIxMx+D7gCvRKXAcu4U347sr0xy+HI3Gf+HjGc9RSbTt/VTwAKIo0GZqw0gAAAAAASUVORK5CYII=) no-repeat center left;
        border: none;
        height: 24px;
        background-size: contain;
        width: 24px;
        color: #1C1F39;
    }

    .btn_receipt {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAuklEQVRIS82V0Q2DMAxEHxN0BLpB1Y26CXQTNmpXYAM2KDIiVQpOSYyMyCfi7vkcGyqcT+Xsz6GAN3AzJhLtXdPGCT5G8yBTu6EBStsWCjsfYKtloWJzAndA7p2bE7gDSlsUF/Tdi39jugcgsMnbYw9+7uR0gLhtqT3YlcAdoI3ucg+SCQbgMn92ZcxyT6wTzQvogetyilqgyXXdeO8JiN/qjyYPH0BtBEnlXTDXAEbftKz051JcgDtgBLt4MxnJLScUAAAAAElFTkSuQmCC) no-repeat center left;
        border: none;
        height: 24px;
        background-size: contain;
        width: 24px;
        color: #1C1F39;
    }

    .btn_email {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABHUlEQVRIS92V4U3DQAyFv0wAGwAbwATABrABnaAwCXQCukHZAJgARoAN2glAH7KlU0jKNVGEVP9JlLPfs599TsPE1kyMz34RXACPwPFI2T6AGfAiTimRB0cjwTNcrJM2wVecngHvA4lOgbeI/Um+rCAJ1lHi044kVyHx4V8EifsA3FWS6Dtv+fZWIOh9ONuoa8CqusxsV4ADopWxvQQeqKXgBwF+2dEXfZ4BSTZBYu9S6q0EZmOgfTiP7G6BRfGeVb4C6p9VVhOUvUh9l/HxJp4SSlzazgQGm6HgSqYpiSRdkzaIQFA1LyvouyuDCSontr7JtYBtv/+rwDHLJg7NPuM+cyuXu8jbaBPHblTBnbBf63ps1p3x+/XLnESib34QPRnKZk0rAAAAAElFTkSuQmCC) no-repeat center left;
        border: none;
        height: 24px;
        background-size: contain;
        width: 24px;
        color: #1C1F39;
    }

    .modal-dialog.modal-custom {
        max-width: 623px;
        font-weight: bold;
        top: 16%;
    }

    .modal-dialog.modal-custom .modal-content {
        border-radius: 5px;
        border: 0;
    }

    .modal-dialog.modal-custom .modal-body {
        padding: 0px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .modal-dialog.modal-custom .modal-content header {
        border-radius: 3px 3px 0 0;
        background-color: rgba(27, 30, 56, 0.07);
        border: 0;
        margin: 10px;
    }

    .modal-dialog.modal-custom .modal-content header .modal-title {
        font-weight: bold;
    }

    .modal-dialog .modal-footer {
        border-top: none;
        padding-left: 20px;
        padding-right: 20px;
    }

    #custom-modal___BV_modal_backdrop_ {
        background-color: rgba(27, 30, 56, 0.25);
    }

    .modal-invite-customer {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
    }

    .modal-invite-customer .form-control.text-message {
        width: 100%;
        height: 313px;
        border-radius: 3px;
    }

    .modal-invite-customer .input-row label {
        width: 240px;
        font-size: 12px;
        font-weight: bold;
    }

    .modal-invite-customer .email-list {
        color: #29BBC1;
    }

    .modal-invite-customer .form-control {
        height: 31px;
        border-width: 1.5px;
        border-radius: 3px;
    }

    .modal-invite-customer input[readonly] {
        opacity: 1;
        color: #000000;
    }

    .modal-invite-customer input[readonly]:focus {
        box-shadow: none;
        border: 1.5px solid rgba(28, 31, 57, 0.1);
    }

    .input-btn {
        font-size: 13px;
        width: 83px;
        height: 38px;
    }

    .modal-backdrop {
        background: red;
    }

    .page-invoices .action-btn {
        min-width: 45px;
        width: 75px;
        max-width: 75px;
    }
</style>
<style>
    .V3 .claimlinq-page .b-table td {
        color: #1C1F39;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 16px;
    }

    .V3 .claimlinq-page .b-table td.number {
        font-weight: bold;
    }

</style>

<template>
  <div class="card">
    <div class="card-block bg-white">
      <h4>Transaction History</h4>

      <block-activity :activity="activity"></block-activity>

    </div>
  </div>
</template>

<script>

import BlockActivity from '@/components/blocks/common/block-activity';
import Axios from "axios";

export default {
  name: "transactions",
  components: {
    BlockActivity,
  },
  props: {
    transactions: {
      type: Array,
      default(){
        return [
          {
            id: 0,
            invoiceId: 0,
            userId: 0,
            paymentType: '',
            paid: 0,
            paidOn: '',
            due: 0,
            createdOn: '',
          }
        ];
      }
    },
    invoice: {
      type: Object,
      default(){
        return {
          id: 0,
          number: '',
          claimNumber: '',
          assessmentId: 0,
          assessmentNumber: '',
          vehicleOwner: '',
          createdOn: '',
          dueDate: '',
          paid: 0,
          due: 0,
          status: '',
          description: '',
          xeroInvoiceNumber: '',
        };
      }
    },
  },
  data(){
    return {
      activity: [],
    }
  },
  methods: {
    loadActivity(){
      if(!this.invoice) return;
      Axios.get(`/ir/activity/get-for-invoice/${this.invoice.id}`)
          .then(response => {
            if(response.data && response.data._status){
              this.activity = response.data.data
            }
          })
          .catch(e => console.error(e));
    }
  },
  watch: {
    invoice(){
      this.loadActivity();
      setTimeout(this.loadActivity, 3000);
    },
    transactions(){
      setTimeout(this.loadActivity, 3000);
    }
  }
}
</script>

<style scoped>

td.dollar-holder {
  vertical-align: top;
  position: relative;
}
td.dollar-holder .vertical-line {
  border-right: lightgray 1px solid;
  position: absolute; top: 40px;
  bottom: 0;
  left: 0;
  width: 20px;
}
.dollar-dot {
  color: white;
  font-size: 14px;
  border-radius: 99999px;
  width: 40px;
  height: 40px;
  background-color: var(--green);
  margin: 0;
  line-height: 40px;
  text-align: center;
  font-weight: bolder;
}
.lineholder-l {
  width: 20px;
  border-right: 1px solid lightgray;
  padding: 0;
}
.lineholder-r {
  width: 20px;
  padding: 0;
}

.transaction-info {
  position: relative;
  border-radius: 5px;
  border: 1px solid lightgray;
  min-width: 600px;
  padding: 10px;
}
.transaction-info::after {
  content: ' ';
  background: none;
  display: block;
  width: 20px;
  height: 10px;
  position: absolute;
  left: -20px;
  top: 4px;
  border: 5px solid rgba(0,0,0,0);
  border-left: 10px solid rgba(0,0,0,0);
  border-right: 10px solid lightgray;
}

.transaction-info table tr th,
.transaction-info table tr td {
  padding: 5px;
}

</style>

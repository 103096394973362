<template>
  <div>
    <div class="tab-content-table">
      <div class="row overflow-hidden">
        <div class="col-12">
          <div class="notes-table use-pagination-block-bottom">
            <b-table
                hover
                responsive
                :items="notes"
                :fields="computedFields"
                :filter="filter"
                :current-page="cardsCurrentPage"
                :per-page="cardsPerPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
            >
              <template v-slot:cell(id)="data">
                <div v-html="filterNote(data.item.text)"></div>
              </template>
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{data.label}}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{data.label}}<i></i></div>
              </template>

              <template v-slot:cell(createdOn)="data">
                {{ data.item.createdOn | formatDatetime }}
              </template>

              <template v-slot:cell(action)="data">
                <div class="d-flex">
                  <div class="d-flex flex-row align-items-center">
                    <a class="d-inline-block px-2" @click.prevent="showEditNoteModal(data.item)">
                      <i class='bx bx-pencil font-20'></i>
                    </a>
                    <a class="d-inline-block px-2 ml-2" @click.prevent="removeNote(data.item)">
                      <div class="nav-link btn_trush" style="zoom: 0.9; margin-bottom: 3px;"></div>
                    </a>
                  </div>
                </div>
              </template>

            </b-table>
          </div>
        </div>
      </div>
    </div>

    <block-pagination
        :role="'Notes'"
        :arrayOfSomeone="notes"
        :arrayOfSomeoneFiltered="notes"
        :currentPage="cardsCurrentPage"
        @changedCurrentPage="value => cardsCurrentPage = value"
        :perPage="cardsPerPage"
        @changedPerPage="value => cardsPerPage = value"
    >
    </block-pagination>

    <b-modal :centered="true" id="noteBoxModal" ref="noteBoxModal" aria-labelledby="noteboxModalLabel" header-class="modal-header-background" footer-bg-variant="light" style="top: 10vh">
      <template v-slot:modal-header>
        <h4 class="modal-title" id="noteboxModalLabel">{{note_id ? 'Edit Note' : 'New Note'}}</h4>
        <button @click="$bvModal.hide('noteBoxModal')" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" style="color:#fff;">&times;</span>
        </button>
      </template>
      <template v-slot:default class="modal-body">
        <div class="form-group">
          <label for="message-text" class="form-control-label">Note:</label>
          <textarea v-model="note" class="form-control" id="message-text" rows="10" style="height: auto;"></textarea>
        </div>
      </template>
      <template v-slot:modal-footer class="modal-footer">
        <button @click="saveNote" type="button" class="btn btn-black btn-green-colored"><i class="ti-save"></i> Save Note</button>
        <button @click="$bvModal.hide('noteBoxModal')" type="button" class="btn btn-secondary" data-dismiss="modal"><i class="ti-close"></i>Close</button>
      </template>
    </b-modal>

  </div>

</template>

<script>
    import Axios from "axios";
    import BlockPagination from '../../../components/utility/block-pagination';
    import {mapGetters} from "vuex";

    export default {
      name: "notes",
      components: {
        BlockPagination,
      },
      computed: {
        ...mapGetters({
          isUserRoleLawyer: 'currentUser/isRoleLawyer',
        }),
        computedFields() {
          let fields =  [
            {label: "Note Details", key: "id", sortable: true, tdClass: 'clickable number', thClass: ''},
            {label: "Created By", key: "createdBy", sortable: true, tdClass: 'clickable ', thClass: 'col-2'},
            {label: "Date", key: "createdOn", sortable: true, tdClass: 'clickable number', thClass: 'col-2'},
          ];
          if (!this.isUserRoleLawyer) {
            fields.push({label: "Action", key: "action", sortable: true, tdClass: 'clickable ', thClass: 'col-1'});
          }
          return fields
        },
      },
      props: {
        notes: {
          type: Array,
          default() {
            return []
          }
        }
      },
      data(){
        return {
          filter:'',
          sortBy: null,
          sortDesc: false,
          note: '',
          note_id: null,
          cardsPerPage: 10,
          cardsCurrentPage: 1,
        };
      },
      methods: {
        filterNote(noteText){
          let note = '';
            note = noteText;
          note = note.replace(/(?:\n)/g, '<br />');
          return note;
        },
        showAddNoteModal(){
          this.note_id = null;
          this.note = '';
          this.$bvModal.show('noteBoxModal');
        },
        showEditNoteModal(item){
          this.note_id = item.id;
          this.note = item.text;
          this.$bvModal.show('noteBoxModal');
        },
        saveNote(){
          console.log('YEEEY')
          this.$bvModal.hide('noteBoxModal');
          if(this.note_id){
            NProgress.start();
            Axios.post(`/ir/invoice/${this.note_id}/edit-note`, {text: this.note})
                .then(response => {
                  if(response.data && response.data._status){
                    this.$emit('notes-updated')
                  } else {
                    console.log(response.data);
                  }
                })
                .catch(e => console.error(e))
                .finally(() => {
                  NProgress.done();
                });
          } else {
            NProgress.start();
            Axios.post(`/ir/invoice/${this.$route.params.invoiceId}/add-note`, {text: this.note})
                .then(response => {
                  if(response.data && response.data._status){
                    this.$emit('notes-updated')
                  } else {
                    console.log(response.data);
                  }
                })
                .catch(e => console.error(e))
                .finally(() => {
                  NProgress.done();
                });
          }
        },
        removeNote(item){
          if(!confirm('Are you sure to delete this note?')){
            return;
          }
          NProgress.start();
          Axios.post(`/ir/invoice/${item.id}/delete-note`)
              .then(response => {
                if(response.data && response.data._status){
                  this.$emit('notes-updated')
                } else {
                  console.log(response.data);
                }
              })
              .catch(e => console.error(e))
              .finally(() => {
                NProgress.done();
              });
        },
      },
    }
</script>


<style scoped>
    .v-context.context-menu-right-click.list-group.invoices-context-menu {
      width: 165px;
    }
    .btn_edit {
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAA70lEQVRIS+2WYQ0CMQxG3ylAAjgAFIADkAAWMHCgBCSAAyzgABygAMiXbAks47rtuMAPliy55LrvtevaraLjUXWsTwwwBbbAwIDvgKXlYAxwBvrWQvffhMQA9wRxea4oNRohpQCtW6RA2gDkvQlpCzAhnwCEkBfNUkDTOfgDzCr5rS2SNyNg7vzeAzdgBailqIrVVo5PcWVFIGMtXgNqgppDYOyKTBAV26QNQOIz4OAAikhTwoJLXDZ+ZEfgm98G8Fuk75NTrIO0ZwHMIxMx+D7gCvRKXAcu4U347sr0xy+HI3Gf+HjGc9RSbTt/VTwAKIo0GZqw0gAAAAAASUVORK5CYII=) no-repeat center left;
      border: none;
      height: 24px;
      background-size: contain;
      width: 24px;
      color: #1C1F39;
    }
    .btn_delete {
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAuklEQVRIS82V0Q2DMAxEHxN0BLpB1Y26CXQTNmpXYAM2KDIiVQpOSYyMyCfi7vkcGyqcT+Xsz6GAN3AzJhLtXdPGCT5G8yBTu6EBStsWCjsfYKtloWJzAndA7p2bE7gDSlsUF/Tdi39jugcgsMnbYw9+7uR0gLhtqT3YlcAdoI3ucg+SCQbgMn92ZcxyT6wTzQvogetyilqgyXXdeO8JiN/qjyYPH0BtBEnlXTDXAEbftKz051JcgDtgBLt4MxnJLScUAAAAAElFTkSuQmCC) no-repeat center left;
      border: none;
      height: 24px;
      background-size: contain;
      width: 24px;
      color: #1C1F39;
    }

    .modal-header-background {
      background: #00b050;
      color: white;
    }
    .btn-green-colored {
      background: #00b050;
      border: 1px solid #009945;
    }
    .btn-green-colored:hover {
      background: #00cc5c;
      border: 1px solid #00b050;
    }
</style>

<style>
    /* Styling b-table in global */

    .notes-table .table td {
      vertical-align: top;
    }

    .notes-table table th:first-child {
      min-width: 250px;
      width: 60%;
    }

    /* resize height - identical to files-tab - files-tab.vue */
    .notes-table table.table th {
      height: 40px;
      padding: 0.75rem 1.25rem;
      border-top: 0;
      border-bottom: 0;
      white-space: nowrap;
    }

    .notes-table table.table tbody > tr:first-child td {
      border-top: 0;
    }

    /* note details width */
    .notes-table .note-details {
      min-width: 250px;
      width: 60%;
    }
</style>
